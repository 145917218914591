import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import usePageTitle from "../../../../hooks/usePageTitle";
import useTerrClientDescriptions from "../../../../hooks/useTerrClientDescriptions";
import { resetFilters } from "../../../../state";
import TableComponent from "./TableComponent";
import useFetchData from "./useFetchData";
import useFilters from "./useFilters";

const refreshTime = 30;
const defaultPagination = { pageIndex: 0, pageSize: 10 };

const StatusTableContainer = ({ pageTitle = "Task Status", preWarmOnly = false }) => {
  const dispatch = useDispatch();
  const [pagination, setPagination] = useState(defaultPagination);
  const [timestampIconDisplay, setTimestampIconDisplay] = useState(true);
  const [onQueuePage, setOnQueuePage] = useState(false);
  const [terrClientDescriptions, setTerrClientDescriptions] = useState({});

  const { appliedFilters, resetAllFilters } = useFilters();
  const { data, loading, nextPaginationKey, fetchData, refreshData, fetchQueuedData, resetData } = useFetchData("/tasks", appliedFilters, preWarmOnly);

  useTerrClientDescriptions(setTerrClientDescriptions);
  usePageTitle(pageTitle);

  useEffect(() => {
    dispatch(resetFilters());
    fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const applyFilterAndFetchData = () => {
    resetData();
    fetchData();
  };

  return (
    <TableComponent
      data={data}
      loading={loading}
      pagination={pagination}
      setPagination={setPagination}
      fetchData={fetchData}
      refreshData={refreshData}
      fetchQueuedData={fetchQueuedData}
      onQueuePage={onQueuePage}
      setOnQueuePage={setOnQueuePage}
      timestampIconDisplay={timestampIconDisplay}
      setTimestampIconDisplay={setTimestampIconDisplay}
      applyFilterAndFetchData={applyFilterAndFetchData}
      resetAllFilters={resetAllFilters}
      preWarmOnly={preWarmOnly}
      nextPaginationKey={nextPaginationKey}
      refreshTime={refreshTime}
      terrClientDescriptions={terrClientDescriptions}
    />
  );
};

export default StatusTableContainer;
