import React, { useCallback, useState } from "react";
import { Snackbar } from "@mui/material";
import Alert from "@mui/material/Alert";

import {
  generateDDFirmColumns,
  generateCommonColumns,
} from "../../clients/profiles/exclusions/ColumnsDD";
import ExclusionsTable from "./ExclusionsTable";

export default function Exclusions({ study, setSubmitMessage }) {
  const [success, setSuccess] = useState(null);
  const [response, setResponse] = useState("");
  const apiCallback = useCallback((apiSuccess, result) => {
    setSuccess(apiSuccess);
    if (apiSuccess) {
      if (result?.response?.data) {
        setResponse(JSON.stringify(result.response.data));
      } else {
        setResponse("Saved Successfully");
      }
    } else {
      setResponse(JSON.stringify(result.response.data));
    }
  }, []);
  return (
    <div>
      <div style={{ padding: 20 }}>
        <ExclusionsTable
          includeFirmNamesData
          study={study}
          direction="exclusions"
          title="Broker Exclusions"
          level="firm"
          url={`lms/api/v1/${study}/exclusion/${study}_exclusion_consortiumdenyfirm`}
          editUrl={`lms/api/v1/${study}/exclusion/${study}_exclusion_consortiumdenyfirm`}
          columns={[
            ...generateDDFirmColumns(),
            {
              header: "Notes",
              accessorKey: "notes",
              editable: "always",
            },

            ...generateCommonColumns(),
          ]}
          apiCallback={apiCallback}
          setSubmitMessage={setSubmitMessage}
        />
      </div>
      <div style={{ padding: 20 }}>
        <ExclusionsTable
          includeFirmNamesData
          study={study}
          direction="exclusions"
          title="Broker/Zip Exclusions"
          level="firmzip"
          url={`lms/api/v1/${study}/exclusion/${study}_exclusion_consortiumdenyfirmzip`}
          editUrl={`lms/api/v1/${study}/exclusion/${study}_exclusion_consortiumdenyfirmzip`}
          columns={[
            ...generateDDFirmColumns(),
            {
              header: "Zip Code",
              accessorKey: "zip_code",
            },
            {
              header: "Notes",
              accessorKey: "notes",
              editable: "always",
            },
            ...generateCommonColumns(),
          ]}
          apiCallback={apiCallback}
          setSubmitMessage={setSubmitMessage}
        />
      </div>
      {" "}
      <div style={{ padding: 20 }}>
        <ExclusionsTable
          includeFirmNamesData
          study={study}
          direction="inclusions"
          title="Broker Inclusions"
          level="firm"
          url={`lms/api/v1/${study}/exclusion/${study}_exclusion_consortiumallowfirm`}
          editUrl={`lms/api/v1/${study}/exclusion/${study}_exclusion_consortiumallowfirm`}
          columns={[
            ...generateDDFirmColumns(),
            {
              header: "Notes",
              accessorKey: "notes",
              editable: "always",
            },

            ...generateCommonColumns(),
          ]}
          apiCallback={apiCallback}
          setSubmitMessage={setSubmitMessage}
        />
      </div>
      <div style={{ padding: 20 }}>
        <ExclusionsTable
          includeFirmNamesData
          study={study}
          direction="inclusions"
          title="Broker/Zip Inclusions"
          level="firmzip"
          url={`lms/api/v1/${study}/exclusion/${study}_exclusion_consortiumallowfirmzip`}
          editUrl={`lms/api/v1/${study}/exclusion/${study}_exclusion_consortiumallowfirmzip`}
          columns={[
            ...generateDDFirmColumns(),
            {
              header: "Zip Code",
              accessorKey: "zip_code",
            },
            {
              header: "Notes",
              accessorKey: "notes",
              editable: "always",
            },
            ...generateCommonColumns(),
          ]}
          apiCallback={apiCallback}
          setSubmitMessage={setSubmitMessage}
        />
      </div>
      <div style={{ padding: 20 }}>
        <ExclusionsTable
          study={study}
          direction="exclusions"
          title="Office Exclusions"
          level="office"
          url={`lms/api/v1/${study}/exclusion/${study}_exclusion_consortiumdenyoffice`}
          editUrl={`lms/api/v1/${study}/exclusion/${study}_exclusion_consortiumdenyoffice`}
          columns={[
            {
              header: "DD Physical Branch ID",
              accessorKey: "dd_physical_branch_id",
              editable: "always",
            },
            {
              header: "Notes",
              accessorKey: "notes",
              editable: "always",
            },
            ...generateCommonColumns(),
          ]}
          apiCallback={apiCallback}
          setSubmitMessage={setSubmitMessage}
        />
      </div>
      <div style={{ padding: 20 }}>
        <ExclusionsTable
          study={study}
          direction="inclusions"
          title="Office Inclusions"
          level="office"
          url={`lms/api/v1/${study}/exclusion/${study}_exclusion_consortiumallowoffice`}
          editUrl={`lms/api/v1/${study}/exclusion/${study}_exclusion_consortiumallowoffice`}
          columns={[
            {
              header: "DD Physical Branch ID",
              accessorKey: "dd_physical_branch_id",
              editable: "always",
            },
            {
              header: "Notes",
              accessorKey: "notes",
              editable: "always",
            },
            ...generateCommonColumns(),
          ]}
          apiCallback={apiCallback}
          setSubmitMessage={setSubmitMessage}
        />
      </div>
      <Snackbar
        open={success !== null}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <div>
          <Alert
            onClose={() => setSuccess(null)}
            severity={success ? "success" : "error"}
          >
            {response}
          </Alert>
        </div>
      </Snackbar>
    </div>
  );
}
