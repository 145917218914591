import React, { useEffect, useState, useMemo } from "react";
import {
  ContactMail as ClientProfileIcon,
  Business as BusinessIcon,
  Check as CheckIcon,
  ChevronLeft as ChevronLeftIcon,
  Menu as MenuIcon,
  NotInterested as NotInterestedIcon,
  ListAlt as StatusTableIcon,
  PersonPinCircle as PersonPinCircleIcon,
  VpnKey as VpnKeyIcon,
  AddCircle as AddTaskIcon,
  Group as GroupIcon,
  Speed as SpeedIcon,
  Storage as StorageIcon,
  StarOutlined as StarOutlinedIcon,
  Fireplace as PrewarmIcon,
  Settings as SettingsIcon,
  GroupAdd as SuperAdminIcon,
} from "@mui/icons-material";
import {
  AppBar,
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Snackbar,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import makeStyles from "@mui/styles/makeStyles";

import { fetchAuthSession } from "aws-amplify/auth";
import clsx from "clsx";
import { useSelector } from "react-redux";
import { Link, Navigate, Route, Routes } from "react-router-dom";
import { GraphQlIcon, DatabaseSyncIcon, AccessControlIcon } from "../components/icons";
import usePrivilege from "../hooks/usePrivilege";
import PageNotFound from "./404";
import ApiAccessControl from "./MISight/access_control";
import MisightAccounts from "./MISight/accounts";
import AccountView from "./MISight/accounts/AccountView";
import UsersImport from "./MISight/accounts/Import/index";
import MisightData from "./MISight/data/MisightData";
import GraphiQL from "./MISight/graphi";
import Performance from "./MISight/performance";
import Prewarm from "./MISight/prewarm";
import VersionsInfo from "./VersionsInfo";
import EditWorkflow from "./analysts_workflow/EditWorkflow";
import WorkflowDetails from "./analysts_workflow/WorkflowDetails";
import ClientProfileDetailsDD from "./clients/profiles/ProfileDetailsDD";
import MisightClientProfileGroupingConfig from "./clients/profiles/athena";
import LmsClientsDD from "./clients/profiles/indexDD";
import ConsortiumExclusions from "./consortium";
import LmsDbRefreshStatus from "./dbrefresh";
import { DisplayClientSpecificMapping } from "./preprocessing_transforms/DisplayClientSpecificMapping";
import { DisplayCommonHeaderMapping } from "./preprocessing_transforms/DisplayCommonHeaderMapping";
import { DisplayCommonTransforms } from "./preprocessing_transforms/DisplayCommonTransforms";
import SettingsPage from "./settings";
import SplitBrokers from "./split_brokers";
import TaskDetails from "./tasks/TaskDetails";
import ClientConfiguration from "./tasks/client_configuration";
import TaskCreateWizard from "./tasks/create_wizard/index";
import StatusTable from "./tasks/status";
import ReportTemplates from "./tasks/templates";
import UserGroups from "./user_groups";

const drawerWidth = 200;
const deploymentEnv = process.env.REACT_APP_CUSTOM_NODE_ENV;

const envColors = Object.freeze({
  local: "white",
  dev: "#debe52",
  stage: "#736dbf",
  prod: "#d85f5f",
});

const envDisplayName = Object.freeze({
  local: "Local",
  dev: "Development",
  stage: "Staging",
  prod: "Production",
});

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  text: {
    padding: theme.spacing(2, 2, 0),
  },
  paper: {
    paddingBottom: 50,
  },
  list: {
    marginBottom: theme.spacing(2),
  },
  subheader: {
    backgroundColor: theme.palette.background.paper,
  },
  taskPopover: {
    minWidth: "90em",
    minHeight: "80em",
  },
  appBottomBar: {
    top: "auto",
    bottom: 0,
  },
  grow: {
    flexGrow: 1,
  },
  fabButton: {
    position: "absolute",
    zIndex: 1,
    top: -30,
    left: 0,
    right: 0,
    margin: "0 auto",
  },
  appTopBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    minHeight: "100vh",
    overflow: "hidden",
    margin: "1.5em",
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawerOpen: {
    width: drawerWidth,
  },
  drawerClose: {
    overflowX: "hidden",
    width: theme.spacing(8),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  appName: {
    fontWeight: 300,
    fontSize: "1.5em",
  },
  appNameColor: {
    fontSize: "1.5em",
    color: theme.palette.text.link,
  },
  deploymentEnv: {
    color: envColors[deploymentEnv],
    textTransform: "capitalize",
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function MainViewDD() {
  const classes = useStyles();
  const pageTitle = useSelector(state => state.application.pageTitle);
  const backendType = useSelector(state => state.application.backendType);
  const cognitoGroups = useSelector(state => state.application.cognitoGroups);
  const groups = useSelector((state) => state.userSettings.groups);

  const [submitMessage, setSubmitMessage] = useState("");
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [severity, setSeverity] = useState("info");
  // Refresh Create Task Component on clicking Plus Button
  const [copiedJWT, setCopiedJWT] = useState(false);

  const updateSubmitMessage = (message, sever = "info") => {
    setSubmitMessage(message);
    setSeverity(sever);
  };

  usePrivilege();

  useEffect(() => {
    document.title = `Braavos ${backendType} Discovery Data | ${pageTitle}`;
  }, [backendType, pageTitle]);

  // Items can be available for LMS, DI or ALL
  const drawerItems = [
    { label: "Status Table", to: "/status", icon: <StatusTableIcon />, backendType: "ALL", group: ["base"] },
    { label: "Create Task / Workflow", to: "/create", icon: <AddTaskIcon />, backendType: "ALL", group: ["base"] },
    { label: "Client Profiles", to: "/client-profiles", icon: <ClientProfileIcon />, backendType: "LMS", group: ["super-admin", "data-ops", "data-ops-management", "client-success"] },
    { label: "Consortium Exclusions", to: "/consortium-exclusions", icon: <NotInterestedIcon />, backendType: "LMS", group: ["super-admin", "data-ops", "data-ops-management", "client-success"] },
    { label: "Misight Client Config", to: "/misight-client-config", icon: <PersonPinCircleIcon />, backendType: "LMS", group: ["super-admin", "data-ops", "data-ops-management", "client-success"] },
    { label: "Split Brokers", to: "/split-brokers", icon: <BusinessIcon />, backendType: "LMS", group: ["super-admin", "data-ops", "data-ops-management", "client-success"] },
    { label: "Report Templates", to: "/report-templates", icon: <StarOutlinedIcon />, backendType: "LMS", group: ["super-admin"] },
    { label: "MISight Accounts", to: "/accounts", icon: <GroupIcon />, backendType: "LMS", group: ["super-admin", "data-ops-management", "client-success"] },
    { label: "LMS MISight Data", to: "/lms-data", icon: <StorageIcon />, backendType: "LMS", group: ["super-admin", "data-ops-management", "client-success"] },
    { label: "API Access Control", to: "/api-access-control", icon: <AccessControlIcon />, backendType: "LMS", group: ["super-admin", "client-success"] },
    { label: "User Groups Management", to: "/user-groups", icon: <SuperAdminIcon />, backendType: "LMS", group: ["super-admin"] },
    { label: "Data Refresh Control", to: "/db-refresh", icon: <DatabaseSyncIcon />, backendType: "LMS", group: ["super-admin", "data-ops-management"] },
    { label: "GraphQL", to: "/graphql", icon: <GraphQlIcon />, backendType: "LMS", group: ["super-admin", "data-ops", "data-ops-management", "client-success"] },
    { label: "Pre Warm", to: "/pre-warm", icon: <PrewarmIcon />, backendType: "LMS", group: ["super-admin", "data-ops", "data-ops-management", "client-success"] },
    { label: "Performance", to: "/performance", icon: <SpeedIcon />, backendType: "LMS", group: ["super-admin", "data-ops", "data-ops-management", "client-success"] },
    { label: "Settings", to: "/settings", backendType: "LMS", icon: <SettingsIcon />, group: ["base"] },
    // Immediately filter for respective services
  ].filter(item => (item.group.some(group => groups.includes(group)) || item.group.includes("base")) && (item.backendType === backendType || item.backendType === "ALL"));

  const contentRoutes = [
    { path: "/", element: <Navigate replace to={{ pathname: "/status" }} />, group: ["base"] },
    { path: "/status", element: <StatusTable />, group: ["base"] },
    { path: "/versions", element: <VersionsInfo />, group: ["super-admin"] },
    { path: "/create", element: <TaskCreateWizard setSubmitMessage={updateSubmitMessage} isDDTask />, group: ["base"] },
    { path: "/edit/:taskIdParam", element: <TaskCreateWizard setSubmitMessage={updateSubmitMessage} />, group: ["base"] },
    { path: "/details/:taskIdParam", element: <TaskDetails isDD />, group: ["base"] },
    { path: "/client-profiles", element: <LmsClientsDD />, group: ["super-admin", "lms-data-ops", "lms-data-ops-management", "lms-client-success"] },
    { path: "/client-configuration", element: <ClientConfiguration />, group: ["base"] },
    { path: "/consortium-exclusions", element: <ConsortiumExclusions setSubmitMessage={updateSubmitMessage} />, group: ["super-admin", "lms-data-ops", "lms-data-ops-management", "lms-client-success"] },
    { path: "/preprocessing-transform/common-transform/studyFileType/:studyFileType/id/:id", element: <DisplayCommonTransforms />, group: ["super-admin", "lms-data-ops", "lms-data-ops-management", "lms-client-success"] },
    { path: "/preprocessing-transform/common-header-mapping/studyFileType/:studyFileType/id/:id", element: <DisplayCommonHeaderMapping />, group: ["super-admin", "lms-data-ops", "lms-data-ops-management", "lms-client-success"] },
    { path: "/preprocessing-transform/client-specific-transform/client/:client/studyFileType/:studyFileType/id/:id", element: <DisplayClientSpecificMapping />, group: ["super-admin", "lms-data-ops", "lms-data-ops-management", "lms-client-success"] },
    { path: "/client-profile/details/:profileId/study/:study", element: <ClientProfileDetailsDD />, group: ["super-admin", "lms-data-ops", "lms-data-ops-management", "lms-client-success"] },
    { path: "/misight-client-config", element: <MisightClientProfileGroupingConfig drawerOpen={drawerOpen} isDD />, group: ["super-admin", "lms-data-ops", "lms-data-ops-management", "lms-client-success"] },
    { path: "/split-brokers", element: <SplitBrokers />, group: ["super-admin", "lms-data-ops", "lms-data-ops-management", "lms-client-success"] },
    { path: "/workflows/details/:workflowNameParam", element: <WorkflowDetails />, group: ["base"] },
    { path: "/workflows/edit/:workflowNameParam", element: <EditWorkflow setSubmitMessage={updateSubmitMessage} />, group: ["base"] },
    { path: "/report-templates", element: <ReportTemplates />, group: ["super-admin"] },
    { path: "/accounts", element: <MisightAccounts />, group: ["super-admin", "data-ops-management", "client-success"] },
    { path: "/lms-data", element: <MisightData />, group: ["super-admin", "data-ops-management", "client-success"] },
    { path: "/db-refresh", element: <LmsDbRefreshStatus />, group: ["super-admin", "data-ops-management"] },
    { path: "/user/:userId", element: <AccountView />, group: ["super-admin", "data-ops-management", "client-success"] },
    { path: "/importUsers", element: <UsersImport />, group: ["super-admin", "data-ops-management", "client-success"] },
    { path: "/pre-warm", element: <Prewarm />, group: ["super-admin", "data-ops", "data-ops-management", "client-success"] },
    { path: "/performance", element: <Performance />, group: ["super-admin", "data-ops", "data-ops-management", "client-success"] },
    { path: "/api-access-control", element: <ApiAccessControl />, group: ["super-admin", "client-success"] },
    { path: "/graphql", element: <GraphiQL />, group: ["super-admin", "data-ops", "data-ops-management", "client-success"] },
    { path: "/settings", element: <SettingsPage />, group: ["base"] },
    { path: "/user-groups", element: <UserGroups />, group: ["super-admin"] },
    { path: "*", element: <PageNotFound />, group: ["base"] },
  ].filter(item => item.group.some(group => cognitoGroups.includes(group)) || item.group.includes("base"));
  function filteredToolbarItems(items) {
    return (
      items?.length > 0 && (
        <>
          <Divider />
          <List>
            {items?.map(({ label, to, icon, otherProps = {} }) => (
              <ListItem
                button
                key={label}
                component={Link}
                to={to}
                {...otherProps}
                style={{ height: 80 }}
              >
                <ListItemIcon>
                  {icon}
                </ListItemIcon>
                <ListItemText primary={label} />
              </ListItem>
            ))}
          </List>
        </>
      )
    );
  }

  const drawerItemsComponent = useMemo(() => (
    filteredToolbarItems(drawerItems)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ), [drawerItems, groups]);

  return (
    <div className={classes.root}>
      {/* --- TOP BAR --- */}
      <AppBar
        position="fixed"
        className={clsx(classes.appTopBar, {
          [classes.appBarShift]: drawerOpen,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={() => { setDrawerOpen(true); }}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: drawerOpen,
            })}
            size="large"
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap>
            <b className={classes.appNameColor}>B</b>
            <b className={classes.appName}>{`raavos ${backendType} Discovery Data`}</b>
            <b className={classes.appName}>{` | ${pageTitle}`}</b>
          </Typography>
          <Box style={{ flexGrow: 1 }} />
          <Tooltip title={copiedJWT ? "Copied!" : "Copy JSON Web Token"}>
            <IconButton
              className={classes.appNameColor}
              onClick={() => {
                fetchAuthSession().then((response) => {
                  if (response && response.tokens.idToken) {
                    navigator.clipboard.writeText(response.tokens.idToken.toString());
                    setCopiedJWT(true);
                    setTimeout(() => setCopiedJWT(false), 3000);
                  }
                });
              }}
              size="large"
            >
              {copiedJWT ? <CheckIcon /> : <VpnKeyIcon />}
            </IconButton>
          </Tooltip>
          <Typography>
            <b className={classes.appName}>{" | "}</b>
            <b className={`${classes.appNameColor} ${classes.deploymentEnv}`}>{`${envDisplayName[deploymentEnv]}`}</b>
          </Typography>
        </Toolbar>
      </AppBar>
      {/*  --- SIDE DRAWER ---  */}
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: drawerOpen,
          [classes.drawerClose]: !drawerOpen,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: drawerOpen,
            [classes.drawerClose]: !drawerOpen,
          }),
        }}
      >
        <div className={classes.toolbar}>
          <IconButton onClick={() => { setDrawerOpen(false); }} size="large">
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <div className={classes.drawerContainer}>
          {drawerItemsComponent}
        </div>
      </Drawer>
      {/* ---CONTENT ---  */}
      <main className={classes.content}>
        <Toolbar />
        <Routes>
          {contentRoutes.map(({ path, element }) => (
            <Route key={path} path={path} element={element} />
          ))}
        </Routes>
      </main>
      {/* MAIN FEEDBACK MESSAGES */}
      <Snackbar
        open={Boolean(submitMessage && submitMessage.length > 0)}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <div>
          <Alert onClose={() => setSubmitMessage("")} severity={severity}>
            {submitMessage}
          </Alert>
        </div>
      </Snackbar>
    </div>
  );
}
